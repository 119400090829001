<template>
    <div>
        <v-container>
            <v-card style="background-color: #F5F5F5" elevation="3">
                <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                    <span v-if="isNew">Nueva</span>
                    <span v-if="!isNew">Editar</span>
                    categoría de artículo
                </div>

              <v-form
                ref="form"
                v-model="isValid"
                autocomplete="off">
              <v-tabs v-model="tab" background-color="transparent" show-arrows>
                  <v-tabs-slider color="blue"></v-tabs-slider>

                  <v-tab href="#tab-artcat">Categoría</v-tab>
                  <v-tab href="#tab-emp">Empresas</v-tab>
                  <v-tab href="#tab-art">Artículos</v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab" touchless>
                  <v-tab-item :value="'tab-artcat'">
                      <v-card style="background-color: #F5F5F5" flat>
                          <!-- FORMULARIO CATEGORIA -->
                          <v-row>
                              <v-col class="px-7 mt-4" cols="6" sm="3" md="3" lg="3" xl="2">
                                  <v-text-field
                                      class="mb-n7"
                                      outlined
                                      dense
                                      text
                                      v-model="artCat.ArtCatCod"
                                      label="Código">
                                  </v-text-field>
                              </v-col>
                          </v-row>
                          <v-row>
                              <v-col class="px-7 mt-2" cols="12" sm="7" md="5" lg="5" xl="3">
                                  <v-text-field
                                      class="mb-n7"
                                      outlined
                                      dense
                                      text
                                      required
                                      :rules="[v => !!v || 'El nombre es obligatorio']"
                                      v-model="artCat.ArtCatNom"
                                      label="Nombre *">
                                  </v-text-field>
                              </v-col>
                          </v-row>
                          <v-row>
                              <v-col class="px-7 mt-2" cols="12" sm="7" md="5" lg="5" xl="3">
                                  <v-text-field
                                      class="mb-n7"
                                      outlined
                                      dense
                                      text
                                      :value="artCat.ArtCatParent != null ? artCat.ArtCatParent.ArtCatNom : ''"
                                      tabindex="-1"
                                      readonly
                                      label="Tipo">
                                  </v-text-field>
                              </v-col>
                          </v-row>
                          <v-row>
                              <v-col class="px-7 mt-2" cols="12" sm="8" md="6" lg="6" xl="4">
                                  <v-textarea
                                      class="mt-0 mb-n7"
                                      outlined
                                      auto-grow
                                      v-model="artCat.ArtCatObs"
                                      label="Observaciones">
                                  </v-textarea>
                              </v-col>
                          </v-row>
                      </v-card>
                  </v-tab-item>
                  <v-tab-item :value="'tab-emp'" eager>
                      <v-card style="background-color: #F5F5F5" flat>
                          <v-row class="mt-0">
                              <!-- EMPRESAS -->
                              <v-col class="px-7" cols="12" sm="9" md="8" lg="7" xl="6">
                                <EmpresasRelacionadas
                                    ref="relatedEmps"
                                    :empList="empDataList" 
                                    :empTotal="empDataTotal" 
                                    :loading="loading" 
                                    @update-data="updateEmpData">
                                </EmpresasRelacionadas>
                              </v-col>
                          </v-row>
                      </v-card>
                  </v-tab-item>
                  <v-tab-item :value="'tab-art'">
                      <v-card style="background-color: #F5F5F5" flat>
                          <!-- ARTICULOS -->
                          <v-row class="mt-0">
                              <v-col class="px-7" cols="12" sm="7" md="5" lg="4" xl="3">
                                  <v-text-field
                                      outlined
                                      dense
                                      required
                                      text
                                      :hide-details="true"
                                      v-model="filtroArtNom"
                                      label="Buscar artículo"
                                      @change="loadRelatedArts">
                                  </v-text-field>
                              </v-col>
                          </v-row>
                          <v-row class="mt-0">
                              <v-col class="px-7" cols="12" sm="9" md="8" lg="7" xl="6">
                                <!-- Botón añadir articulo -->
                                <v-btn
                                    :loading="loading"
                                    :disabled="editArt"
                                    @click="onBtnNuevoArt"
                                    color="primary"
                                    small
                                    class="mb-2">
                                    Añadir artículo
                                </v-btn>
                                
                                <v-data-table
                                    :headers="artHeaders"
                                    :items="artDataRows"
                                    :options.sync="artOptions"
                                    :footer-props="{itemsPerPageOptions: [15]}"
                                    :server-items-length="artTotalDataRows"
                                    :loading="artLoading"
                                    style="overflow: scroll"
                                    mobile-breakpoint="0"
                                    class="elevation-1"
                                    dense>
                                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                                    <template v-slot:item.Opt="{ item }">
                                        <v-btn
                                            color="error"
                                            class="mr-2 my-1"
                                            small
                                            @click.stop="onBtnShowArtDeleteModal(item)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <!-- NUEVO ARTICULO FORMULARIO -->
                                    <template v-if="editArt" slot="body.append">
                                        <tr class="new-row">
                                            <td>
                                                <ArtFind class="mb-n7" v-model="newArt" label="Artículo"  @change="validateArt"></ArtFind>
                                            </td>
                                            <td style="min-width: 250px" align ="right">
                                                <v-btn
                                                    :loading="artLoading"
                                                    :disabled="!isValidNewArt"
                                                    @click="onBtnSaveArt"
                                                    color="primary"
                                                    class="mt-2 mb-4 mr-2"
                                                    small>
                                                    Aceptar
                                                </v-btn>
                                                
                                                <v-btn
                                                    @click="onBtnCancelArt"
                                                    class="mt-2 mb-4 mr-2"
                                                    small>
                                                    Cancelar
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                              </v-col>
                          </v-row>
                      </v-card>
                  </v-tab-item>
              </v-tabs-items>

              <!-- ACEPTAR / CANCELAR / BORRAR -->
              <v-row class="mt-2" justify="center">
                  <v-btn
                      :loading="loading"
                      :disabled="!isValid"
                      @click="onBtnSave"
                      color="primary"
                      class="mt-2 mb-4 mr-2"
                      style="width:100px">
                      Aceptar
                  </v-btn>
                  
                  <v-btn
                      class="mt-2 mb-4 mr-2"
                      style="width:100px"
                      @click="goToList">
                      Cancelar
                  </v-btn>

                  <!-- && canDelete" -->
                  <v-btn v-if="!isNew"
                      color="error"
                      class="mt-2 mb-4"
                      style="width:100px"
                      @click="onBtnShowDeleteModal">
                      Borrar
                  </v-btn>
              </v-row>
              </v-form>
            </v-card>
        </v-container>

        <!-- Modales de borrado -->
        <!-- Borrar Categoria  -->
        <v-dialog
            v-model="showDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR CATEGORIA
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar la categoría? Esta acción no se podrá deshacer.
                </v-card-text>

                <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    :loading="loading"
                    color="primary"
                    @click="onBtnDelete">
                    Aceptar
                </v-btn>
                <v-btn @click="onBtnCancelDelete">
                    Cancelar
                </v-btn>
              </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Borrar articulo -->
        <v-dialog
            v-model="showArtDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                  BORRAR ARTÍCULO
              </v-card-title>

              <v-card-text class="mt-2">
                  ¿Desea borrar el artículo?
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      :loading="artLoading"
                      color="primary"
                      @click="onBtnArtDelete">
                      Aceptar
                  </v-btn>
                  <v-btn @click="onBtnCancelArtDelete">
                      Cancelar
                  </v-btn>
              </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import ArtFind from "../components/ArtFind.vue";
import EmpresasRelacionadas from "../components/EmpresasRelacionadas.vue";
export default ({
    components: {
        ArtFind, EmpresasRelacionadas
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            tab: null,
            loading: false,
            isNew: true,
            isValid: false,
            // canDelete: false,
            showDeleteDialog: false,
            showArtDeleteDialog: false,
            filtroArtNom: '',
            artCat: {
                ArtCatId: 0,
                ArtCatCod: '',
                ArtCatNom: '',
                ArtCatObs: '',
                ArtCatParent: null,
                ArtCatIdParent: null
            },
            // Datos tabla empresas
            empDataTotal: 0,
            empDataList: [],
            // Tabla articulos
            artLoading: false,
            artTotalDataRows: 0,
            artDataRows: [],
            artOptions: {page: 1, itemsPerPage: 15},
            artHeaders: [
                { text: 'Artículos relacionados', width: 325, sortable: false, value: 'ArtNom' },
                { text: '', width: 100, sortable: false, value: 'Opt', align: 'end' }
            ],
            artOptionsLoaded: false,
            // Edición artículos
            editArt: false, // Indica si se esta editando el nuevo articulo
            newArt: {},
            isValidNewArt: false,
            selectedArt: null, // Indica el articulo seleccionada para el borrado
            addedArts: [],
            deletedArts: []
        }
    },
    watch: {
      artOptions: {
        handler () {
          // Evitamos la llamada a la API en la primera carga de las options
          if(!this.artOptionsLoaded) {
            this.artOptionsLoaded = true;
            return;
          }
          this.loadRelatedArts();
        },
        deep: true
      },
      // artCat: {
      //   handler () {
      //       this.validate();
      //   },
      //   deep: true,
      // },
    },
    mounted() {
      this.isNew = this.$route.meta.isNew;
      
      if(!this.isNew) {
        this.artCat.ArtCatId = this.$route.params.id;
        this.loadData();
        this.loadRelatedArts();
      } else {
        // Init ArtCatParent
        if(this.$store.state.ArtCatParentData != null) {
          this.artCat.ArtCatParent = this.$store.state.ArtCatParentData;
          this.artCat.ArtCatIdParent = this.$store.state.ArtCatParentData.ArtCatId;
        }

        // Usar eager en la v-tab-item para poder tener la ref cargada
        this.$refs.relatedEmps.loadDefaultEmps();
      }
    },
    methods: {
      goToList() {
        this.$router.push('/categorias-articulo');
      },

      //#region Llamadas a la API
      getDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_ARTCAT',
            ArtCatId: this.artCat.ArtCatId,
            EmpId: this.empId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/artcat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.ArtCat;
            // const canDelete = result.data.CanDelete;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item,
                  // canDelete
                })
            }, 1000)
          }).catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar la categoría.');
                this.goToList();
            }
          });
        })
      },
      artCatHasArtFromApi(artId) {
        this.artLoading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'ARTCAT_HAS_ART',
            ArtCatId: this.artCat.ArtCatId,
            ArtId: artId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/artcat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const any = result.data;

            setTimeout(() => {
                this.artLoading = false;
                resolve({
                any
              })
            }, 1000)
          }).catch(error => {
            if(error != null) {
                this.artLoading = false;
                this.isValidNewArt = false;
            }
          });
        })
      },
      getRelatedArtListFromApi() {
        this.artLoading = true;

        var NumRegsPag = this.artOptions.itemsPerPage;
        var NumPag = this.artOptions.page;
        
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_CATEGORY_RELATED_ART_LIST_PAGINATED',
            NumRegsPag: NumRegsPag,
            NumPag: NumPag,
            ArtCatId: this.artCat.ArtCatId,
            ArtNom: this.filtroArtNom,
            EmpId: this.empId,
            AddedArtIds: this.addedArts,
            DeletedArtIds: this.deletedArts
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/art", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data.EntsList;
            const total = result.data.EntsTotCount;

            setTimeout(() => {
                this.artLoading = false
                resolve({
                  items,
                  total
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.artLoading = false;
                alert('Error al cargar los artículos.');
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        // Asignamos los IDs de los objetos hijos
        const empIds = this.empDataList.map(m => m.EmpId);

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'SAVE_ENTITY',
            ArtCat: this.artCat,
            EmpIds: empIds,
            AddedArtIds: this.addedArts,
            DeletedArtIds: this.deletedArts
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/artcat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al guardar la categoría.');
            }
          });
        })
      },
      deleteDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'DEL_ENTITY',
            ArtCatId: this.artCat.ArtCatId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/artcat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al borrar la categoría.');
            }
          });
        })
      },
      //#endregion
      
      //#region Carga de datos
      loadData() {
        this.getDataFromApi()
          .then(data => {
            if(data.item == null) {
              alert('Categoría no encontrada.');
              this.goToList();
              return;
            }

            this.artCat = data.item;
            // this.canDelete = data.canDelete;
            
            // Cargamos los datos de empresas
            this.empDataTotal = data.item.ArtCatEmp.length;
            this.empDataList = [];
            data.item.ArtCatEmp.forEach(i => {
              this.empDataList.push({
                EmpId: i.Emp.EmpId,
                EmpNom: i.Emp.EmpNom
              });
            });
          });
      },
      loadRelatedArts() {
        this.getRelatedArtListFromApi()
        .then(data => {
          this.artDataRows = data.items
          this.artTotalDataRows = data.total
        });
      },
      //#endregion

      //#region Añadir articulos
      onBtnNuevoArt() {
        this.editArt = true;
        this.newArt = null;
        this.validateArt();
      },
      //#endregion

      //#region Click guardado
      onBtnSave() {
          this.validate();
          if(!this.isValid) { return; }
          this.postDataToApi().then(data => {
            if(data.item.ArtCatId != null) {
                this.goToList();
            }
            else {
              alert('Error al guardar la categoría.');
            }
          });
      },
      onBtnSaveArt() {
        if(this.newArt == null) { return; }
        const index = this.deletedArts.indexOf(this.newArt.ArtId);
        if(index == -1) {
          this.addedArts.push(this.newArt.ArtId);
        } else {
          this.deletedArts.splice(index, 1);
        }
        this.newArt = null,
        this.editArt = false;
        this.loadRelatedArts();
      },
      //#endregion

      //#region Click borrar
      onBtnDelete() {
        this.deleteDataFromApi().then(data => {
          if (data.item.Success) {
            this.showDeleteDialog = false;
            this.goToList();
          }
          else {
            alert(data.item.Message);
          }
        });
      },
      //#endregion

      //#region Cancelar creación de articulo
      onBtnCancelArt() {
        this.editArt = false;
      },
      //#endregion

      //#region Eliminar articulos
      removeArt(item) {
        const index = this.addedArts.indexOf(item.ArtId);
        if(index == -1) {
          this.deletedArts.push(item.ArtId);
        } else {
          this.addedArts.splice(index, 1);
        }
      },
      onBtnArtDelete() {
        this.removeArt(this.selectedArt);
        this.selectedArt = null;
        this.showArtDeleteDialog = false;
        this.loadRelatedArts();
      },
      //#endregion

      //#region Gestión de modales
      onBtnShowDeleteModal() {
        this.showDeleteDialog = true;
      },
      onBtnShowArtDeleteModal(item) {
        this.selectedArt = item;
        this.showArtDeleteDialog = true;
      },
      onBtnCancelDelete() {
        this.showDeleteDialog = false;
      },
      onBtnCancelArtDelete() {
        this.selectedArt = null;
        this.showArtDeleteDialog = false;
      },
      //#endregion

      //#region Evento actualización empresas
      updateEmpData(data) {
        this.empDataList = data.list;
        this.empDataTotal = data.total;
      },
      //#endregion

      //#region Validar formulario
      validate() {
        this.$refs.form.validate();
        // if (this.artCat == null) {
        //     this.isValid = false;
        //     return;
        // }
        // this.isValid = this.artCat.ArtCatNom != null && this.artCat.ArtCatNom != '';
      },
      validateArt() {
        if(this.newArt == null) {
          this.isValidNewArt = false;
          return;
        }
        // Si esta añadido no se puede volver a añadir
        if(this.addedArts.indexOf(this.newArt.ArtId) != -1) {
          this.isValidNewArt = false;
          return;
        }
        // Si esta borrado se puede volver a añadir
        if(this.deletedArts.indexOf(this.newArt.ArtId) != -1) {
          this.isValidNewArt = true;
          return;
        }
        // Comprobamos si ya pertenece a la categoría
        this.artCatHasArtFromApi(this.newArt.ArtId).then(data => {
          this.isValidNewArt = !data.any;
        });
      }
      //#endregion
    } 
})
</script>
